import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
} from "@mui/material";

import { CloudUser } from "../../users/typedefs/CloudUser";
import { ProfileType } from "../../profiles/typedefs/ProfileType";
import { profilesAreEqual } from "../../app/apis/firebase/dbUtils";

/***************************************************************************/

interface Props {
  profiles_passedDown: ProfileType[];
  user_passedDown: CloudUser;
  setUser_passedDown: (user: CloudUser) => void;
  variant: "modifyDelete" | "new";
  handleUserModify_PassedDown: (modifiedUser: CloudUser) => void;
  handleUserDelete_PassedDown: (deletedUser: CloudUser) => void;
  handleNewUser_PassedDown: (user: CloudUser) => void;
}

/***************************************************************************/
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/***************************************************************************/

export default function UserForm(props: Props) {
  console.log(
    "+++props.user_passedDown.profile",
    props.user_passedDown.profile
  );

  const [user, setUser] = React.useState<CloudUser | null>(null);

  return (
    <FormControl fullWidth>
      <FormGroup row sx={{ justifyContent: "space-between", width: "100%" }}>
        {
          <Stack
            direction="column"
            spacing={3}
            sx={{ width: "100%" /*height: "100%" */ }}
          >
            <TextField
              id="userEmailInput"
              label="Felhasználó Email Címe"
              variant="outlined"
              value={user ? user.email : props.user_passedDown.email}
              sx={{ width: "100%" /*height: "100%" */ }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUser({
                  ...props.user_passedDown,
                  email: event.target.value as string,
                });
              }}
            />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={user ? user.profile : props.user_passedDown.profile}
              label="User profile"
              onChange={(event: any) => {
                console.log("+++props.profiles_passedDown", [
                  ...props.profiles_passedDown,
                ]);
                console.log(
                  "+++ event.target.value as string",
                  event.target.value as string
                );
                let tempPermissions = props.profiles_passedDown.filter(
                  (profile) => profile.Id === (event.target.value as string)
                )[0].permissions;
                console.log("+++tempPermissions", { ...tempPermissions });
                if (tempPermissions) {
                  setUser({
                    ...props.user_passedDown,
                    profile: event.target.value as string,
                    permissions: tempPermissions,
                  });
                }
              }}
            >
              {props.profiles_passedDown.map((value: ProfileType) => (
                <MenuItem value={value.Id}>{value.Id}</MenuItem>
              ))}
            </Select>
          </Stack>
        }

        {props.variant === "modifyDelete" ? (
          <React.Fragment>
            <Button
              color="error"
              variant="contained"
              sx={{ width: "100%", height: "100%", mt: 1 }}
              onClick={() => {
                if (user) {
                  console.log("+++deleting");
                  props.handleUserDelete_PassedDown(user)
                };
              }}
            >
              Felhasználó törlése
            </Button>
            <Button
              color="warning"
              variant="contained"
              sx={{ width: "100%", height: "100%", mt: 1 }}
              onClick={() => {
                if (user) {
                  console.log("+++modifying", {...user});
                  console.log({...user});
                  props.handleUserModify_PassedDown(user)
                };
              }}
            >
              Felhasználó módosítása
            </Button>
          </React.Fragment>
        ) : (
          <Button
            color="success"
            variant="contained"
            sx={{ width: "100%", height: "100%" }}
            onClick={() => {
              if (user) {
                console.log("+++creating");
                props.handleNewUser_PassedDown(user)
              };
            }}
          >
            Új felhasználó rögzítése
          </Button>
        )}
      </FormGroup>
    </FormControl>
  );
}
