import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button, Container, Grid, Paper, Stack } from "@mui/material";

import { withScreenSize, withParentSize } from "@visx/responsive";
import { ParentSize } from "@visx/responsive";
import MeterValueCard from "./MeterValueCard";

import {
  MeterValueType,
  MeasurandEnumType,
  SampledValueType,
} from "mtcharger-messages/dist/MeterValuesRequest";
import { useEffect } from "react";

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useLocation } from "react-router";

import VisxAreaChart from "./VisxAreaChart";
import Firebase from "../../app/apis/firebase/Firebase";

const db = getFirestore(new Firebase().app);

// Add a new document with a generated id.
//const docRef = addDoc(collection(db, "transactions"), generateTransaction());
//console.log("Document written with ID: ", docRef.id);

let interpolationCounter = 0;
console.log(" interpolationCounter is 0 ");

const TXUpdateIntervalMs = 10;
const renderFreqMs = 1000;

export default function MeterValuesScreen(props: any) {
  const [meterValues, setMeterValues] = React.useState<MeterValueType[]>([]);

  const [stationTransactions, setStationTransactions] = React.useState([]);
  const [transaction, setTransaction] = React.useState<Transaction | null>(
    null
  );
  const [transactionId, setTransactionId] = React.useState<any>();
  const [freezeTime, setFreezeTime] = React.useState<boolean>(false);
  const location = useLocation();

  const transId = location.pathname.split("/")[4];
  console.log("transId " + transId);
  const q = query(
    collection(db, "transactions"),
    where("id", "==", transId),
    orderBy("startTime", "desc")
  );

  React.useEffect(() => {
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const transactions: any = [];
      const transactionIds: any = [];
      querySnapshot.forEach((doc: any) => {
        transactions.push(doc.data());

        transactionIds.push(doc.id);
      });
      setStationTransactions(transactions);
      setTransaction(transactions[0]);
      console.log(transactions);
      if (transactions[0])
        setMeterValues(
          transactions[0].values.sort(
            (a: MeterValueType, b: MeterValueType) =>
              parseInt(a.timestamp) - parseInt(b.timestamp)
          )
        );
      interpolationCounter = 0;

      setTransactionId(transactionIds[0]);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const interpolate = () => {
    if (interpolationCounter <= TXUpdateIntervalMs) {
      setMeterValues((prevValues) => {
        let incrementedTimestamp =
          parseInt(prevValues[prevValues.length - 1].timestamp) + renderFreqMs;
        let newTimestamp = Math.min(Date.now(), incrementedTimestamp);
        return [
          ...prevValues,
          {
            ...prevValues[prevValues.length - 1],
            timestamp: newTimestamp.toString(),
          },
        ];
      });
      interpolationCounter++;
    }
  };

  return (
    <Container maxWidth="xl">
      <Stack sx={{ width: "100%" }} spacing={0}>
        {
          /*<Typography variant="h5" sx={{ ml: "-150px", mt: "30px" , mb: "30px"}}>Tranzakció azonosító:{transactionId}</Typography>*/
          null
        }

        <Typography variant="h5">{/*stationsArray[0].id*/ null}</Typography>
        <Grid container spacing={3} mb={2} mt={1}>
          {meterValues.length > 0
            ? meterValues[meterValues.length - 1].sampledValue
                .sort((a: SampledValueType, b: SampledValueType) =>
                  a.measurand!.localeCompare(b.measurand!)
                )
                .map(({ measurand, value }) => (
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={4} spacing={0}>
                    <Stack
                      direction="column"
                      justifyItems="space-evenly"
                      alignItems="center"
                      spacing={0}
                    >
                      <MeterValueCard
                        freezeTime_PassedDown={freezeTime}
                        label={measurand ? measurand : null}
                        measured={
                          measurand
                            ? measurand.split(".")[0] === "Energy"
                              ? (value / 3600).toFixed(2)
                              : measurand.split(".")[0] === "Power"
                              ? (value / 1000).toFixed(0)
                              : value
                            : 0
                        }
                        units={
                          measurand
                            ? measurand.split(".")[0] === "Current"
                              ? "A"
                              : measurand.split(".")[0] === "Energy"
                              ? "kWh"
                              : measurand.split(".")[0] === "Power"
                              ? "kW"
                              : measurand.split(".")[0] === "SoC"
                              ? "%"
                              : measurand.split(".")[0] === "Voltage"
                              ? "V"
                              : ""
                            : ""
                        }
                        subscript={
                          "legutóbbi adat: " +
                          new Date(
                            parseInt(
                              meterValues[meterValues.length - 1].timestamp
                            )
                          ).toLocaleString("hu-HU")
                        }
                        chart={
                          <ParentSize>
                            {({ width, height }) => (
                              <VisxAreaChart
                                measurand={measurand ? measurand : "Voltage"}
                                meterValues={meterValues}
                                width={width}
                                height={height}
                                renderFreqMs={renderFreqMs}
                                interpolate={interpolate}
                                freezeTime_PassedDown={freezeTime}
                                setFreezeTime_PassedDown={setFreezeTime}
                                ended={transaction!.ended}
                              />
                            )}
                          </ParentSize>
                        }
                      />
                    </Stack>
                  </Grid>
                ))
            : null}
        </Grid>
      </Stack>
    </Container>
  );
}
