import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Token } from "typescript";
import ChargeTokenTypeSelector from "./TypeSelector";
import { TokenStatus } from "../constants/TokenStatus";
import { TokenType } from "../constants/TokenType";

interface Props {
  chargeToken_PassedDown: ChargeToken;
  //chargeToken_PassedDown: ChargeToken;
  handleTokenModify_PassedDown: (modifiedToken: ChargeToken) => void;
  handleTokenDelete_PassedDown: (deletedToken: ChargeToken) => void;
  modifyButtonColor: any;
  modifyButtonText: any;
  deleteButtonColor: any;
  deleteButtonText: any;
}
export default function ModifyOrDeleteForm(props: Props) {
  //const [chargeToken, setChargeToken] = React.useState<ChargeToken>(props.chargeToken_PassedDown);
  const [status, setStatus] = React.useState<string>(
    props.chargeToken_PassedDown.status
  );

  return (
    <Box sx={{ mt: "25px", height: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <FormControl fullWidth>
            <InputLabel id="select-label">TokenStatus</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="TokenStatus"
              onChange={(event: any) => setStatus(event.target.value as string)}
            >
              {Object.values(TokenStatus)
                .filter((x) => typeof x === "string")
                .map((value: TokenStatus) => (
                  <MenuItem value={value}>{TokenStatus[value]}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <Button
            color={props.modifyButtonColor}
            variant="contained"
            sx={{ width: "100%", height: "100%" }}
            onClick={() =>
              props.handleTokenModify_PassedDown({
                ...props.chargeToken_PassedDown,
                status: status ? status : props.chargeToken_PassedDown.status,
              })
            }
          >
            {props.modifyButtonText}
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <Button
            color={props.deleteButtonColor}
            variant="contained"
            sx={{ width: "100%", height: "100%" }}
            onClick={() =>
              props.handleTokenDelete_PassedDown(props.chargeToken_PassedDown)
            }
          >
            {props.deleteButtonText}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
