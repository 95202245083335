import {
  MeterValueType,
  SampledValueType,
} from "mtcharger-messages/dist/MeterValuesRequest";

export default function sumUpMeterValues(transactions: Transaction[]) {
  let currentTotal = 0;
  let currentCount = 0;
  let energyTotal = 0;
  let powerTotal = 0;
  let powerCount = 0;
  let voltageTotal = 0;
  let voltageCount = 0;

  transactions.forEach((transaction: Transaction) => {
    if (!transaction.values) return;

    //Calculate *total* energy (add up the latest metervalue from each transaction)
    const latestMeterValue = transaction.values.sort(
      (a: MeterValueType, b: MeterValueType) =>
        parseInt(b.timestamp) - parseInt(a.timestamp)
    )[0];
    latestMeterValue.sampledValue
      .filter(
        (meterValue: SampledValueType) =>
          meterValue.measurand &&
          meterValue.measurand === "Energy.Active.Net"
      )
      .forEach((meterValue: SampledValueType) => {
        energyTotal += meterValue.value;
      });

    //Calculate *average* current, power and voltage
    transaction.values.forEach((value: MeterValueType) => {
      if (!value.sampledValue) return;
      value.sampledValue.forEach((sampledValue: SampledValueType) => {
        if (!(sampledValue.measurand && sampledValue.value)) return;
        const measurand = sampledValue.measurand.toString();
        const value = sampledValue.value;
        switch (measurand.split(".")[0]) {
          case "Current":
            currentTotal += value;
            currentCount++;
            break;
          case "Power":
            powerTotal += value;
            powerCount++;
            break;
          case "Voltage":
            voltageTotal += value;
            voltageCount++;
            break;
        }
      });
    });
  });
  return {
    currentAvg: Math.floor(currentTotal / currentCount ? currentTotal / currentCount : 0),
    energyTotal: Math.floor(energyTotal/3600),
    powerAvg: Math.floor(powerTotal / powerCount ? powerTotal / powerCount : 0),
    voltageAvg: Math.floor(voltageTotal / voltageCount ? voltageTotal / voltageCount : 0),
  };
}
