import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { CloudUser } from "../../../users/typedefs/CloudUser";
import Firebase from "./Firebase";

import { ProfileType } from "../../../profiles/typedefs/ProfileType";

import * as firebaseAuthApi from "firebase/auth";
import { UserCredential } from "firebase/auth";
import { UserStatus } from "../../../users/typedefs/CloudUser";
import { CurrentUserPermissiontype } from "../../App";
import AppPermissionException from "./AppPermissionException";
import { ExistingFirestoreCollection } from "../../../users/typedefs/MTFirestore";

/**
 * CRUD functions
 */

export type FirestoreCRUDParamsType = {
  firebase: Firebase;
  db?: Firestore | null;
  record: CloudUser | ChargeToken | ProfileType;
  collectionName: string;
  currentUserGlobalPermissions: CurrentUserPermissiontype;
  currentUserCompanyPermissions: CurrentUserPermissiontype;
};

/**
 *
 * @param firestoreRecordParams
 * @returns
 */

export async function createRecordInDB(
  firestoreRecordParams: FirestoreCRUDParamsType
): Promise<boolean> {
  const {
    firebase,
    db,
    record,
    collectionName,
    currentUserGlobalPermissions,
    currentUserCompanyPermissions,
  } = firestoreRecordParams;
  if (
    !currentUserGlobalPermissions[
      collectionName as keyof typeof currentUserGlobalPermissions
    ].create ||
    !currentUserCompanyPermissions[
      collectionName as keyof typeof currentUserCompanyPermissions
    ].create
  )
    throw new AppPermissionException(
      "No permission to create " + collectionName + " record!"
    );
  try {
    const db = getFirestore(firebase.app);
    if (db) {
      console.log("db: ", { ...db });
      console.log("new record: ", { ...record });
      /**
       * hibalehetőségek:
       * security rules NEM
       * ez a fv NEM
       * konkrét object NEM
       * stringkezelés: NEM
       * addoc vs setdoc NEM
       */
      const response: any = await setDoc(
        doc(db, collectionName, record.Id),
        record
      );
      console.log("response: ", { ...response });
    } else {
      throw new AppPermissionException("DB IS NULL");
    }
  } catch (err) {
    console.error(
      "Failed to create " + collectionName + " in DB, error: ",
      err
    );
    return false;
  }
  return true;
}

export async function createRecordInDBWithoutId(
  firestoreRecordParams: FirestoreCRUDParamsType
): Promise<boolean> {
  const {
    firebase,
    db,
    record,
    collectionName,
    currentUserGlobalPermissions,
    currentUserCompanyPermissions,
  } = firestoreRecordParams;
  if (
    !currentUserGlobalPermissions[
      collectionName as keyof typeof currentUserGlobalPermissions
    ].create ||
    !currentUserCompanyPermissions[
      collectionName as keyof typeof currentUserCompanyPermissions
    ].create
  )
    throw new AppPermissionException(
      "No permission to create " + collectionName + " record!"
    );
  try {
    const db = getFirestore(firebase.app);
    if (db) {
      console.log("db: ", { ...db });
      console.log("new record: ", { ...record });
      /**
       * hibalehetőségek:
       * security rules NEM
       * ez a fv NEM
       * konkrét object NEM
       * stringkezelés: NEM
       * addoc vs setdoc NEM
       */
      const response: any = await addDoc(
        collection(db, collectionName),
        record
      );
      const newId = (await getDoc(response)).id;
      const response2: any = await setDoc(doc(db, collectionName, newId), {
        ...record,
        Id: newId,
      });
      console.log("response: ", { ...response });
    } else {
      throw new AppPermissionException("DB IS NULL");
    }
  } catch (err) {
    console.error(
      "Failed to create " + collectionName + " in DB, error: ",
      err
    );
    return false;
  }
  return true;
}

/**
 *
 * @param firestoreRecordParams
 * @returns boolean
 */

export async function updateRecordInDB(
  firestoreRecordParams: FirestoreCRUDParamsType
): Promise<boolean> {
  const {
    firebase,
    record,
    collectionName,
    currentUserGlobalPermissions,
    currentUserCompanyPermissions,
  } = firestoreRecordParams;
  if (
    !currentUserGlobalPermissions[
      collectionName as keyof typeof currentUserGlobalPermissions
    ].update ||
    !currentUserCompanyPermissions[
      collectionName as keyof typeof currentUserCompanyPermissions
    ].update
  )
    throw new AppPermissionException(
      "+++No permission to update " + collectionName + " record!"
    );
  try {
    const db = getFirestore(firebase.app);
    console.log("+++ updated user record in dbUtils: ", { ...record });
    const Id = record.Id;
    const response: any = await updateDoc(doc(db, collectionName, Id), record);
  } catch (err) {
    console.error(
      "+++Failed to update " + collectionName + " in DB, error: ",
      err
    );
    return false;
  }
  return true;
}

export async function updateRecordInDB_USER(firestoreRecordParams: {
  firebase: Firebase;
  db?: Firestore | null;
  record: CloudUser;
  collectionName: string;
  currentUserGlobalPermissions: CurrentUserPermissiontype;
  currentUserCompanyPermissions: CurrentUserPermissiontype;
}): Promise<boolean> {
  const {
    firebase,
    record,
    collectionName,
    currentUserGlobalPermissions,
    currentUserCompanyPermissions,
  } = firestoreRecordParams;
  if (
    !currentUserGlobalPermissions[
      collectionName as keyof typeof currentUserGlobalPermissions
    ].update ||
    !currentUserCompanyPermissions[
      collectionName as keyof typeof currentUserCompanyPermissions
    ].update
  )
    throw new AppPermissionException(
      "+++No permission to update " + collectionName + " record!"
    );
  try {
    const db = getFirestore(firebase.app);
    console.log("+++ updated user record in dbUtils: ", { ...record });
    const Id = record.Id;
    const response: any = await updateDoc(doc(db, collectionName, Id), {
      Id: record.Id,
      email: record.email ? record.email : "",
      status: record.status ? record.status : "",
      profile: record.profile ? record.profile : "",
      initialPassword: record.initialPassword ? record.initialPassword : "",
      company: record.company ? record.company : "",
      permissions: record.permissions ?{ ...record.permissions }: "",
    });
  } catch (err) {
    console.error(
      "+++Failed to update " + collectionName + " in DB, error: ",
      err
    );
    return false;
  }
  return true;
}

/**
 *
 * @param firestoreRecordParams
 * @returns
 */

export async function deleteRecordInDB(
  firestoreRecordParams: FirestoreCRUDParamsType
): Promise<boolean> {
  const {
    firebase,
    record,
    collectionName,
    currentUserGlobalPermissions,
    currentUserCompanyPermissions,
  } = firestoreRecordParams;
  if (
    !currentUserGlobalPermissions.users.delete ||
    !currentUserCompanyPermissions.users.delete
  )
    throw new AppPermissionException(
      "No permission to delete " + collectionName + " record!"
    );
  try {
    const db = getFirestore(firebase.app);
    const response: any = await deleteDoc(doc(db, collectionName, record.Id));
  } catch (err) {
    console.error(
      "Failed to delete  " + collectionName + " in DB, error:" + err
    );
    return false;
  }
  return true;
}

/**
 * OTHERS
 */

/**
 *
 * @param firebase
 * @param user
 * @param currentUserPermissions
 * @returns
 */
export async function updateUserProfileInDB(
  firebase: Firebase,
  user: CloudUser,
  currentUserGlobalPermissions: CurrentUserPermissiontype | null,
  currentUserCompanyPermissions: CurrentUserPermissiontype | null
): Promise<boolean> {
  try {
    const db = getFirestore(firebase.app);
    const response: any = await updateDoc(doc(db, "users", user.Id), {
      //setCurrentStateemail: user.email,
      //status: user.status,
      profile: user.profile,
      permissions: user.permissions,
    });
  } catch (err) {
    console.error("Failed to update user in DB, error:  ", err);
    return false;
  }
  return true;
}

/**
 *
 * @param firebase
 * @param user
 * @param currentUserPermissions
 * @returns
 */

export async function activateUserInDatabase(
  firebase: Firebase,
  user: CloudUser,
  currentUserGlobalPermissions: CurrentUserPermissiontype | null,
  currentUserCompanyPermissions: CurrentUserPermissiontype | null
): Promise<boolean> {
  if (
    (currentUserGlobalPermissions &&
      !currentUserGlobalPermissions.users.create) ||
    (currentUserCompanyPermissions &&
      !currentUserCompanyPermissions.users.create)
  )
    throw new AppPermissionException("No permission to activate user record!");
  try {
    const db = getFirestore(firebase.app);
    const response: any = await updateDoc(doc(db, "users", user.Id), {
      status: UserStatus.active,
    });
  } catch (err) {
    console.error("Failed to activate user in db, error:  ", err);
    return false;
  }
  return true;
}
