import { ThemeProvider } from "@mui/material/styles";
import { Box, CssBaseline, useMediaQuery } from "@mui/material";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";

import dashboardMenu from "../dashboard/constants/dashboardMenu";
import appTheme from "./theme/AppTheme";

import React, { useRef, useState } from "react";
import appRoutes from "./routing/appRoutes";
import SelectedMenuItemContext from "./contexts/SelectedMenuItem";
import GeneralRouteTreeMT from "./routing/GeneralRouteTreeMT";
import FirebaseDataContext from "./contexts/FirebaseData";
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  getDocsFromServer,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { setUpSnapshotListener } from "./apis/firebase/dbUtils";
import { User, UserCredential } from "firebase/auth";
import Firebase from "./apis/firebase/Firebase";
import { ProfileType } from "../profiles/typedefs/ProfileType";
import { CloudUser, Profile } from "../users/typedefs/CloudUser";
import { ExistingFirestoreCollection } from "../users/typedefs/MTFirestore";
import * as firebaseAuthApi from "firebase/auth";
import {
  activateUserInDatabase,
  createRecordInDB,
  createRecordInDBWithoutId,
  deleteRecordInDB,
  updateRecordInDB,
  updateRecordInDB_USER,
  updateUserProfileInDB,
} from "./apis/firebase/dbFunctions";
import { startTime } from "..";
import { PermissionType } from "../profiles/typedefs/PermissionType";
import AppPermissionException from "./apis/firebase/AppPermissionException";

export type CurrentUserPermissiontype = {
  authTokens: {
    create: boolean;
    delete: boolean;
    read: boolean;
    update: boolean;
  };
  stations: {
    create: boolean;
    delete: boolean;
    read: boolean;
    update: boolean;
  };
  transactions: {
    create: boolean;
    delete: boolean;
    read: boolean;
    update: boolean;
  };
  users: {
    create: boolean;
    delete: boolean;
    read: boolean;
    update: boolean;
  };
  profiles: {
    create: boolean;
    delete: boolean;
    read: boolean;
    update: boolean;
  };
};

/***************************************************************************/

interface Props {
  firebase: Firebase;
}

/***************************************************************************/

function App(props: Props) {
  //console.log("function App" + " called");

  const [selectedMenuItem, setSelectedMenuItem] = React.useState(() => {
    let mainIndex = 0;
    let childIndex = 0;
    dashboardMenu.forEach((mainItem, index1) => {
      mainItem.children.forEach((childItem, index2) => {
        if (window.location.href.includes(childItem.route)) {
          mainIndex = index1;
          childIndex = index2;
        }
      });
    });
    return dashboardMenu[mainIndex].children[childIndex];
  });
  const [authUser, setAuthUser] = useState<UserCredential | null>(null);

  const [userInfo, setUserInfo] = useState<User | null>(null);
  const [db, setDb] = useState<Firestore | null>(null);
  const [currentUserRecord, setCurrentUserRecord] = useState<CloudUser | null>(
    null
  );
  const [stations, setStations] = useState<Station[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [chargeTokens, setChargeTokens] = useState<ChargeToken[]>([]);
  const [profiles, setProfiles] = useState<ProfileType[]>([]);
  const [users, setUsers] = useState<any[]>([]);

  const [currentUserGlobalPermissions, setCurrentUserGlobalPermissions] =
    useState<CurrentUserPermissiontype | null>(null);

  const [currentUserCompanyPermissions, setCurrentUserCompanyPermissions] =
    useState<CurrentUserPermissiontype | null>(null);

  const isSmUp = useMediaQuery(appTheme.breakpoints.up("md"));

  const handleMenuClick = (newMenuItem: MenuItem) => {
    setSelectedMenuItem(newMenuItem);
  };
  //Wait for db and userInfo to arrive (it does not load on component load right away)

  console.log(`App.tsx first render - elapsed: ${Date.now() - startTime} ms`);

  if (props.firebase.userInfo) {
    console.log(
      `App.tsx userInfo arrived - elapsed: ${Date.now() - startTime} ms`
    );
  }
  if (props.firebase.app) {
    console.log(
      `App.tsx firt app obj arrived - elapsed: ${Date.now() - startTime} ms`
    );
  }

  React.useEffect(() => {
    const timeOutID = setTimeout(() => {
      setUserInfo(props.firebase.userInfo);
      setDb(getFirestore(props.firebase.app));
    }, 1000);
    return () => clearTimeout(timeOutID);
  });
  /*
  React.useEffect(() => {
    const timeOutID = setTimeout(() => {
      setUserInfo(props.firebase.userInfo);
      setDb(getFirestore(props.firebase.app));
    },3000);
    return () => clearTimeout(timeOutID);
  }, [props.firebase]);
  
  React.useEffect(() => {
    const timeOutID = setTimeout(() => {
      setUserInfo(props.firebase.userInfo);
      setDb(getFirestore(props.firebase.app));
    },4000);
    return () => clearTimeout(timeOutID);
  }, [props.firebase]);
  React.useEffect(() => {
    const timeOutID = setTimeout(() => {
      setUserInfo(props.firebase.userInfo);
      setDb(getFirestore(props.firebase.app));
    },10000);
    return () => clearTimeout(timeOutID);
  }, [props.firebase]);
  */

  /*** 1.
   * CURRENT USER
   * Get the db record of the current user (once). */
  React.useEffect(() => {
    if (!db || !userInfo || !userInfo.email) return;
    const userEmail = userInfo.email.toString();
    console.log("*** userInfo.uid", userInfo.uid);

    const q = query(
      collection(db, "users"),
      where("Id", "==", userInfo.uid),
      limit(10)
    );
    (async () => {
      try {
        const docRef = doc(db, "users", userInfo.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          console.log(docSnap.id, "*** => ", docSnap.data().email);
          setCurrentUserRecord(docSnap.data() as CloudUser);
          setCurrentUserGlobalPermissions(docSnap.data().permissions.global);
          setCurrentUserCompanyPermissions(docSnap.data().permissions.company);
          console.log("***doc.data().permissions.company", {
            ...docSnap.data().permissions.company,
          });
          console.log("***doc.data().permissions.global", {
            ...docSnap.data().permissions.global,
          });
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.log("***=> Failed *******************, error:  ", err);
      }
    })();
  }, [db, userInfo]);

  /*** 2.
   * CURRENT USER PROFILE
   * Add listener for the single profile record of the current user. */

  /*** 3.
   * STATIONS (read authorization based on 1. and 2. above)
   * Add listener for the all station records that are visible to the user. */
  React.useEffect(() => {
    if (
      !db ||
      !userInfo ||
      !userInfo.email ||
      !currentUserGlobalPermissions ||
      !currentUserCompanyPermissions
    ) {
      console.log(
        "=> useffect STATIONS -------!db || !userInfo || !userInfo.email || !currentUserPermissions.stations"
      );
      return;
    }
    let q = null;
    const userCompany = userInfo.email.split("@")[1];
    if (currentUserGlobalPermissions.stations.read) {
      q = query(collection(db, "stations"));
    } else if (currentUserCompanyPermissions.stations.read) {
      q = query(collection(db, "stations"), where("owner", "==", userCompany));
    } else {
      console.log("=>The user does not have permission to view stations!");
      return;
    }
    return setUpSnapshotListener(
      (resultItems: Station[]) => {
        console.log("=> stationResults");
        console.log("=> ", [...resultItems]);
        setStations(resultItems);
      },
      "stations",
      q,
      db,
      userInfo
    );
  }, [
    db,
    userInfo,
    currentUserCompanyPermissions,
    currentUserGlobalPermissions,
  ]);

  /*** 4.
   * TRANSACTIONS (read authorization based on 1. and 2. above)
   * Add listener for the all transaction records that are visible to the user. */
  React.useEffect(() => {
    if (
      !db ||
      !currentUserRecord ||
      !userInfo ||
      !currentUserGlobalPermissions ||
      !currentUserCompanyPermissions ||
      !(stations.length > 0)
    ) {
      console.log(
        "=> useffect TRANSACTIONS ------- !db  !currentUserRecord ||!userInfo || !currentUserPermissions.transactions || !(stations.length > 0)"
      );
      return;
    }
    let q = null;
    if (currentUserGlobalPermissions.transactions.read) {
      q = query(collection(db, "transactions"), orderBy("startTime", "desc"));
    } else if (currentUserCompanyPermissions.transactions.read) {
      const userCompany = userInfo.email!.split("@")[1];
      const allowedStations = stations.map((station) => station.id);
      q = query(
        collection(db, "transactions"),
        where("station", "in", allowedStations /* MAX 10 stations! */),
        orderBy("startTime", "desc")
      );
    } else {
      console.log("=> The user does not have permission to view transactions!");
      return;
    }
    return setUpSnapshotListener(
      (resultItems: Transaction[]) => {
        console.log("=> transactionResults");
        console.log("=> ", [...resultItems]);
        setTransactions(resultItems);
      },
      "transactions",
      q,
      db,
      userInfo
    );
  }, [
    db,
    userInfo,
    currentUserGlobalPermissions,
    currentUserCompanyPermissions,
    stations,
  ]);

  /*** 5.
   * TOKENS (read authorization based on 1. and 2. above)
   * Add listener for the all token records that are visible to the user. */
  React.useEffect(() => {
    if (
      !db ||
      !userInfo ||
      !currentUserGlobalPermissions ||
      !currentUserCompanyPermissions
    ) {
      console.log(
        "=> useffect TOKENS ------- !db  !currentUserRecord ||!userInfo || !currentUserPermissions.transactions || !(stations.length > 0)"
      );
      return;
    }
    let q = null;
    if (currentUserGlobalPermissions.authTokens.read) {
      q = query(collection(db, "authTokens") /*orderBy("status"))*/);
    } else if (currentUserCompanyPermissions.authTokens.read) {
      const userCompany = userInfo.email!.split("@")[1];
      q = query(
        collection(db, "authTokens"),
        where("owner", "==", userCompany)
        //orderBy("status")
      );
    } else {
      console.log("=> The user does not have permission to view authTokens!");
      return;
    }
    return setUpSnapshotListener(
      (resultItems: ChargeToken[]) => {
        console.log("=> tokenResults");
        console.log("=> ", [...resultItems]);
        setChargeTokens(resultItems);
      },
      "authTokens",
      q,
      db,
      userInfo
    );
  }, [
    db,
    userInfo,
    currentUserGlobalPermissions,
    currentUserCompanyPermissions,
    transactions,
  ]);

  /*** 6.
   * USERS (read authorization based on 1. and 2. above)
   * Add listener for the all user records that are visible to the user. */
  React.useEffect(() => {
    if (
      !db ||
      !userInfo ||
      !currentUserGlobalPermissions ||
      !currentUserCompanyPermissions
    ) {
      console.log(
        "=> useffect USER ------- !db || !userInfo || !currentUserPermissions"
      );
      return;
    }
    let q = null;
    if (currentUserGlobalPermissions.users.read) {
      q = query(collection(db, "users") /*orderBy("email")*/);
    } else if (currentUserCompanyPermissions.users.read) {
      const userCompany = userInfo.email!.split("@")[1];
      q = query(
        collection(db, "users"),
        where("company", "==", userCompany)
        //orderBy("email")
      );
    } else {
      console.log("=> The user does not have permission to view users!");
    }
    return setUpSnapshotListener(
      (resultItems: any) => {
        console.log("=> userResults");
        console.log("=> ", [...resultItems]);
        setUsers(resultItems);
      },
      "users",
      q,
      db,
      userInfo
    );
  }, [
    db,
    userInfo,
    currentUserGlobalPermissions,
    currentUserCompanyPermissions,
  ]);

  /*** 7.
   * PROFILES (read authorization based on 1. and 2. above)
   * Add listener for the all profile records that are visible to the user. */
  React.useEffect(() => {
    if (
      !db ||
      !userInfo ||
      !userInfo.email ||
      !currentUserGlobalPermissions ||
      !currentUserCompanyPermissions
    ) {
      console.log(
        "=> useffect PROFILES -------!db || !userInfo || !userInfo.email || !currentUserPermissions.stations"
      );
      return;
    }
    let q = null;
    const userCompany = userInfo.email.split("@")[1];
    if (currentUserGlobalPermissions.profiles.read) {
      q = query(collection(db, "profiles"));
    } else if (currentUserCompanyPermissions.profiles.read) {
      q = query(collection(db, "profiles"), where("owner", "==", userCompany));
    } else {
      console.log("=>The user does not have permission to view profiles!");
      return;
    }
    return setUpSnapshotListener(
      (resultItems: any[]) => {
        console.log("=> profileResults");
        console.log("=> ", [...resultItems]);
        setProfiles(resultItems);
      },
      "profiles",
      q,
      db,
      userInfo
    );
  }, [
    db,
    userInfo,
    currentUserCompanyPermissions,
    currentUserGlobalPermissions,
  ]);

  //USERS

  const handleNewUser = async (newUser: CloudUser) => {
    // await createUserInDB(firebase, newUser, currentUserPermissions);
    try {
      if (!currentUserGlobalPermissions || !currentUserCompanyPermissions) {
        throw new AppPermissionException("Permissions object is null!");
      }
      await createRecordInDBWithoutId({
        firebase: props.firebase,
        record: newUser,
        collectionName: ExistingFirestoreCollection.users,
        currentUserGlobalPermissions: currentUserGlobalPermissions,
        currentUserCompanyPermissions: currentUserCompanyPermissions,
      });
    } catch (err) {
      console.error(err);
    }
    //await handleUpdateUsers();
    const creatorAuth = props.firebase.auth;
    const creatorCredential = props.firebase.currentUserCredential;

    /*f (!(await addUserToDatabase(props.firebase, newUser))) {
      console.error("FAILED: await addUserToDatabase(props.firebase, newUser))")

      return;
    }*/
    /*
    if (!(await createFirebaseUser(props.firebase, newUser, "123456"))) {
      console.error(
        "FAILED: await createFirebaseUser(props.firebase, newUser, 123456))"
      );
      return;
    }*/

    firebaseAuthApi
      .createUserWithEmailAndPassword(
        firebaseAuthApi.getAuth(),
        newUser.email,
        "123456"
      )
      .then((result) => {
        /*console.log(
          "newUserAuth: " + JSON.stringify(props.firebase.auth.currentUser)
        );
        console.log(
          "newUserCreds: " +
            JSON.stringify(props.firebase.currentUserCredential)
        );
        console.log("newUserAuth: " + JSON.stringify(props.firebase.userInfo));
*/
        //await props.firebase.resetPassword(newUser.email);
        firebaseAuthApi
          .sendPasswordResetEmail(firebaseAuthApi.getAuth(), newUser.email)
          .then((result) => {
            firebaseAuthApi
              .signOut(firebaseAuthApi.getAuth())
              .then((result) => {
                console.log("entered .then ");
                firebaseAuthApi
                  .signInWithCredential(creatorAuth, creatorCredential)
                  .then((result) => {
                    console.log("entered signInWithCredential.then ");
                    console.log("creatorAuth: " + JSON.stringify(creatorAuth));
                    console.log(
                      "creatorCreds: " + JSON.stringify(creatorCredential)
                    );
                    activateUserInDatabase(
                      props.firebase,
                      newUser,
                      currentUserGlobalPermissions,
                      currentUserCompanyPermissions
                    ).catch((error: any) => {
                      console.log(
                        "could not activate user {error} " + { error }
                      );
                    });
                  })
                  .catch((error: any) => {
                    console.log(
                      "could not sign in again dfvasfvdfv {error} " + { error }
                    );
                  });

                /*
      if (!activateUserInDatabase(props.firebase, newUser)) {
        console.error(
          "FAILED: await activateUserInDatabase(props.firebase, newUser))"
        );

        return;
      }
      */
              })
              .catch((error: any) => {
                console.log("could not sign out {error} " + { error });
              });
          })
          .catch((error: any) => {
            console.log("password reset didnt work: " + { error });
          });

        /* if (!(await forceReSignIn(props.firebase))) {
      props.firebase.signOut();
    }*/
      });
  };

  const handleModifiedUser = async (modifiedUser: CloudUser) => {
    try {
      if (!currentUserGlobalPermissions || !currentUserCompanyPermissions) {
        throw new AppPermissionException("Permissions object is null!");
      }
      await updateRecordInDB_USER({
        firebase: props.firebase,
        record: modifiedUser,
        collectionName: ExistingFirestoreCollection.users,
        currentUserGlobalPermissions: currentUserGlobalPermissions,
        currentUserCompanyPermissions: currentUserCompanyPermissions,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeletedUser = async (deletedUser: CloudUser) => {
    //await deleteUserInDB(firebase, deletedUser, currentUserPermissions);
    try {
      if (!currentUserGlobalPermissions || !currentUserCompanyPermissions) {
        throw new AppPermissionException("Permissions object is null!");
      }
      await deleteRecordInDB({
        firebase: props.firebase,
        record: deletedUser,
        collectionName: ExistingFirestoreCollection.users,
        currentUserGlobalPermissions: currentUserGlobalPermissions,
        currentUserCompanyPermissions: currentUserCompanyPermissions,
      });
    } catch (err) {
      console.error(err);
    }
  };

  //PROFILES

  const handleNewProfile = async (profile: ProfileType) => {
    //await setProfileInDB(firebase, profile, currentUserPermissions);
    try {
      if (!currentUserGlobalPermissions || !currentUserCompanyPermissions) {
        throw new AppPermissionException("Permissions object is null!");
      }
      await createRecordInDB({
        firebase: props.firebase,
        record: profile,
        collectionName: ExistingFirestoreCollection.profiles,
        currentUserGlobalPermissions: currentUserGlobalPermissions,
        currentUserCompanyPermissions: currentUserCompanyPermissions,
      });
    } catch (err) {
      console.error(err);
    }
    //await handleUpdateUsers();
  };

  const handleModifiedProfile = async (modifiedProfile: ProfileType) => {
    try {
      if (!currentUserGlobalPermissions || !currentUserCompanyPermissions) {
        throw new AppPermissionException("Permissions object is null!");
      }
      await updateRecordInDB({
        firebase: props.firebase,
        record: modifiedProfile,
        collectionName: ExistingFirestoreCollection.profiles,
        currentUserGlobalPermissions: currentUserGlobalPermissions,
        currentUserCompanyPermissions: currentUserCompanyPermissions,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeletedProfile = async (deletedProfile: ProfileType) => {
    //await deleteProfileInDB(firebase, deletedProfile, currentUserPermissions);
    try {
      if (!currentUserGlobalPermissions || !currentUserCompanyPermissions) {
        throw new AppPermissionException("Permissions object is null!");
      }
      await deleteRecordInDB({
        firebase: props.firebase,
        record: deletedProfile,
        collectionName: ExistingFirestoreCollection.profiles,
        currentUserGlobalPermissions: currentUserGlobalPermissions,
        currentUserCompanyPermissions: currentUserCompanyPermissions,
      });
    } catch (err) {
      console.error(err);
    }
  };

  //TOKENS

  const handleNewToken = async (newToken: ChargeToken) => {
    try {
      if (!currentUserGlobalPermissions || !currentUserCompanyPermissions) {
        throw new AppPermissionException("Permissions object is null!");
      }
      await createRecordInDB({
        firebase: props.firebase,
        //db: db,
        record: newToken,
        collectionName: /*ExistingFirestoreCollection.authTokens*/ "authTokens",
        currentUserGlobalPermissions: currentUserGlobalPermissions,
        currentUserCompanyPermissions: currentUserCompanyPermissions,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleModifiedToken = async (modifiedToken: ChargeToken) => {
    try {
      if (!currentUserGlobalPermissions || !currentUserCompanyPermissions) {
        throw new AppPermissionException("Permissions object is null!");
      }
      await updateRecordInDB({
        firebase: props.firebase,
        record: modifiedToken,
        collectionName: ExistingFirestoreCollection.authTokens,
        currentUserGlobalPermissions: currentUserGlobalPermissions,
        currentUserCompanyPermissions: currentUserCompanyPermissions,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeletedToken = async (deletedToken: ChargeToken) => {
    try {
      if (!currentUserGlobalPermissions || !currentUserCompanyPermissions) {
        throw new AppPermissionException("Permissions object is null!");
      }
      await deleteRecordInDB({
        firebase: props.firebase,
        record: deletedToken,
        collectionName: ExistingFirestoreCollection.authTokens,
        currentUserGlobalPermissions: currentUserGlobalPermissions,
        currentUserCompanyPermissions: currentUserCompanyPermissions,
      });
    } catch (err) {
      console.error(err);
    }
  };

  /********************************************************************************* */

  const handleUpdateUsers = async (users: CloudUser[]) => {
    //await deleteProfileInDB(props.firebase, deletedProfile);
    users.forEach(async (user: CloudUser) => {
      await updateUserProfileInDB(
        props.firebase,
        user,
        currentUserGlobalPermissions,
        currentUserCompanyPermissions
      );
    });
  };

  return (
    <FirebaseDataContext.Provider
      value={{
        firebase: props.firebase,
        stations: stations,
        transactions: transactions,
        chargeTokens: chargeTokens,
        userInfo: userInfo,
        users: users,
        profiles: profiles,
        handleNewUser: handleNewUser,
        handleModifiedUser: handleModifiedUser,
        handleDeletedUser: handleDeletedUser,
        handleNewProfile: handleNewProfile,
        handleModifiedProfile: handleModifiedProfile,
        handleDeletedProfile: handleDeletedProfile,
        handleUpdateUsers: handleUpdateUsers,
        handleNewToken: handleNewToken,
        handleModifiedToken: handleModifiedToken,
        handleDeletedToken: handleDeletedToken,
        isSmUp: isSmUp,
        setRefresh: (newAuthUser: UserCredential) => setAuthUser(newAuthUser),
      }}
    >
      <SelectedMenuItemContext.Provider
        value={{
          selectedMenuItem: selectedMenuItem,
          handleMenuSelection: handleMenuClick,
        }}
      >
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <GeneralRouteTreeMT routeMap={appRoutes} />
        </ThemeProvider>
      </SelectedMenuItemContext.Provider>
    </FirebaseDataContext.Provider>
  );
}

export default App;
