import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Token } from "typescript";
import ChargeTokenTypeSelector from "./TypeSelector";
import { TokenStatus } from "../constants/TokenStatus";
import { TokenType } from "../constants/TokenType";

interface Props {
  handleNewToken_PassedDown: (newToken: ChargeToken) => void;
  buttonColor: any;
  buttonText: any;
}

export default function NewTokenForm(props: Props) {
  const [chargeToken, setChargeToken] = React.useState<ChargeToken>({
    //Default values of a new token
    Id: "dfdxf",
    email: "",
    name: "",
    owner: "",
    status: "Accepted",
    token: "ydfv",
    type: "ISO14443",
  });

  return (
    <Box sx={{ mt: "25px", height: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TextField
            id="tokenInput"
            label="Új RFID azonosító"
            variant="outlined"
            value={chargeToken.token}
            sx={{ width: "100%" /*height: "100%" */ }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setChargeToken((prevSettings: any) => {
                return {
                  ...prevSettings,
                  token: event.target.value as string,
                  Id: event.target.value as string,
                };
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TextField
            id="tokenName"
            label="név"
            variant="outlined"
            value={chargeToken.name}
            sx={{ width: "100%" /*height: "100%" */ }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setChargeToken((prevSettings: any) => {
                return {
                  ...prevSettings,
                  name: event.target.value as string,
                };
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TextField
            id="email"
            label="email cím"
            variant="outlined"
            value={chargeToken.email}
            sx={{ width: "100%" /*height: "100%" */ }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setChargeToken((prevSettings: any) => {
                return {
                  ...prevSettings,
                  email: event.target.value as string,
                  owner: (event.target.value as string).split("@")[1],
                };
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            color={props.buttonColor}
            variant="contained"
            sx={{ width: "100%", height: "100%" }}
            onClick={() => {
              console.log("newtoken: ", {...chargeToken});
              props.handleNewToken_PassedDown(chargeToken)
            }}
          >
            {props.buttonText}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
